import React,{useState,useEffect} from 'react'
import axios from 'axios'
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBus } from '@fortawesome/free-solid-svg-icons';

    
const TopOperators = () => {

  const apiUrl = process.env.REACT_APP_API_URL;
  const apiKey = process.env.REACT_APP_API_KEY;
  const apiToken = process.env.REACT_APP_API_TOKEN;

  const [operators, setOperators] = useState([]);
  const [seeMoreOperators, setSeeMoreOperators] = useState(false);    

  useEffect(() => {
    axios.get(apiUrl+'/master/all-operators', { 
      headers: { 
        'YESBUS_API_KEY': apiKey,
        Authorization: `Bearer ${apiToken}`,
      }
    })
    .then(res => {
      if(res.data.status==200){
        setOperators(res.data.data);
      }else{
        setOperators([]);
      }
    })
    .catch(err => {
      console.log(err);
      setOperators([]);
    })
  }, []);

  return (
    <>
        <section className="top-routs top-operators">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-6">
                <h3 className="title-section">Poupular Operators</h3>
              </div>
              {
                operators.length>12 &&
                <div className="col-md-6 text-right all_routes">
                  <Link routerlink="routes" className="border-btn bh" onClick={()=>setSeeMoreOperators(!seeMoreOperators)}>
                    {
                      seeMoreOperators==false ? 'See All Operators' : 'See Less Operators'
                    }
                  </Link>
                </div>
              }
              <div className="col-md-12">
                <ul>
                  {operators.map((operator, index) => (
                    <li key={index} style={index>11 && seeMoreOperators==false ? {display:'none'} : {display:''}}>
                      <Link to={'/bus-ticket/'+operator.from+'-to-'+operator.to+'/'+operator.id} className="cursor" style={{textDecoration: "none"}}>
                        <FontAwesomeIcon icon={faBus} />
                        <span>{operator.name}</span>
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
              {/* {
                operators.length>12 &&
                <div className="col-md-12">
                  <div className="text-center">
                    <Link routerlink="routes" className="border-btn bh" onClick={()=>setSeeMoreOperators(!seeMoreOperators)}>
                      {
                        seeMoreOperators==false ? 'See More' : 'See Less'
                      }
                    </Link>
                  </div>
                </div>
              } */}
            </div>
          </div>
        </section>
    </>
  )
}

export default TopOperators
import React from 'react'

const TermAndCondition = () => {
  return (
    <>
        <section class="terms_and_cond">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-lg-12 col-md-12">
                        <div class="terms_and_cond_main privay_policy">
                            <h2>Terms and Conditions</h2>
                            <p>Bus ticket reservation - Terms and Conditions</p>
                            <p>These terms and conditions (collectively, hereinafter referred to as the "Terms") govern your ("User") access to and use of the website, www.yesbus.in and the yesBus mobile applications ("Website"). The Website is owned, managed and operated by PUSPA SERVICES ("YesBus").</p>
                            <p>Please read these Terms carefully before using the Website, which is an online marketplace. For the purpose of clarity, it is hereby stated that the Website is not engaged in providing any services by itself but is a platform, which merely connects the bus operators with the bus travellers.</p>
                            <p>These Terms are an electronic record in terms of the applicable legislation. This electronic record is generated by a computer system and does not require any physical or digital signatures. By using the Website, you signify your agreement to be bound by these Terms.</p>
                            <p>yesBus allows You to surf the Website or buy bus tickets for yourself, your family and your friends, without registering on the Website. You shall be deemed to have read, understood and expressly accepted these Terms, which shall govern the desired transaction of bus ticket booking through the Website for all purposes, and shall be binding on You. All Your rights and liabilities and/or the Website with respect to Your use of the Website and availing bus ticket booking facility ("Facility") provided by the Website shall be governed by these Terms.</p>
                            <p>PLEASE NOTE THAT YOUR USE OF THE WEBSITE IN ANY WAY SIGNIFIES YOUR ACCEPTANCE TO THE TERMS. By agreeing to these Terms, You also agree to yesBus' other Website policies; including but not limited to, (i) Privacy Policy, as amended from time to time; and (ii) Cookie Policy.</p>
                            <p>In addition to these Terms, any other ‘terms of service’ of the application-programming interface (“API”) with which yesBus may tie-up would form an integral part of these Terms (hereinafter referred to as the “API Terms”). However, in the event of a conflict between such API Terms and these Terms, these Terms provided herein shall prevail.</p>
                            <p>Both You and yesBus are individually referred to as 'Party' and collective referred to as 'Parties', in these Terms. Terminology:</p>
                            <p>For the purpose of these Terms, wherever the context so requires "You" or "User" (would mean 'yesBus user') shall mean any natural or legal person who has agreed to become a buyer of the bus tickets and/or an availer of the Facility being offered on the Website. The term "We", "Us", "Our" shall mean yesBus and/or its affiliates.</p>
                            <p>Our Website: yesBus is an online Market Place, which gives prospective travelers and bus operators a platform to connect. In light of the foregoing, yesBus wishes to state the following:The Website is a marketplace, which merely lists the inventory of bus operators who are registered with the Website, but does not own the services per se. Hence, the limited role of the Website is to connect You with the bus operator and vice versa.</p>
                            <p>You understand and agree that the fare of the bus tickets listed and/or offered for sale by the bus operator on the Website, is solely determined by the respective bus operator, and yesBus has no control over the fare of the tickets listed on the Website. In the event, the minimum and/or the maximum fare of the tickets, is in contravention of any law and/or any order or judgment of any regulatory authority/Tribunal/Court, it shall be the sole responsibility of the respective bus operator to ensure that such fare is in compliance with the law/order/judgment, and yesBus shall in no way be liable for such contravention of the law/order/judgment.</p>
                            <p>In light of the foregoing, You agree that any liability in relation to any services being offered on the Website in accordance with the applicable laws shall be that of the bus operator and not yesBus. In case of any deficiency of service, You agree to pursue any such action arising from such deficiency of service against the bus operator and not the yesBus.</p>
                            <p>You further agree and acknowledge that the yesBus is only a facilitator and is not and cannot be a party to or control in any manner any transactions on the Website. Accordingly, the contract of sale of services on the Website shall be a strictly bipartite contract between You and the bus operators listed on the Website.</p>
                            <p>You further agree and acknowledge that all the 'discounts/coupons (if any)' on the Website/prices at which the tickets are being sold by the bus operator on the Website is merely an “invitation to offer” in accordance with the applicable law and shall not be construed to be an “offer” in accordance with the applicable law. Hence, in light of the foregoing, when you agree to purchase the bus tickets listed on the Website by the bus operator, that would be termed as a 'valid offer' under the applicable law Subsequently, when the same is being confirmed by the bus operator it tantamount to be a 'valid contract' as per the applicable law.</p>
                            <h6>User Account:</h6>
                            <p>yesBus may offer You the facility of opening user account on the Website. This user account can be linked to the Your email-id or phone number or both. For Your use of the Website, either by registering an account with yesBus or using the Website as a guest (unregistered user), You agree to the following:</p>
                            <p>You are solely responsible for maintaining the confidentiality of Your account and password and for restricting access to Your device to prevent unauthorized access to Your account when You use the Website by registering an account with yesBus.</p>
                            <p>By using the Website, either as a guest user or registered user, You authorize yesBus and its agents to access third party sites, including but not limited to that of banks and other payment gateways, designated by the bank or on the bank's behalf for retrieving requested information for processing of a customer generated transaction on the Website. In the event that You use the Website through a registered account, You agree to accept responsibility for all activities that occur through your yesBus account.</p>
                            <p>In the event that You use the Website through a registered account, You should take all necessary steps to ensure that the password is kept confidential and secure and should inform yesBus immediately if You have any reason to believe that Your password has been compromised, or if the password is being, or is likely to be, used in an unauthorized manner. yesBus shall not be liable for any losses You may incur owing to any compromised login credentials of Your yesBus account. Please ensure that the details You provide yesBus are correct, complete and up to date and inform yesBus immediately of any changes to the information that You provide at the time of registering Your account with yesBus. yesBus will not be responsible for screening, censoring or otherwise controlling transactions, including ascertaining whether the transaction is legal and valid as per the laws of the land, if you are resident/national of a country other than India.</p>
                            <p>You warrant that You will abide by all such additional procedures and guidelines, as modified from time to time, in connection with Your use of Website. You shall also be liable to comply with all applicable laws and regulations regarding use of the Website with respect to the jurisdiction concerned for each transaction. Website may be used for Your personal and non-commercial purposes only. Therefore, You are not allowed to re-sell, deep-link, use, copy, monitor (e.g. spider, scrape), display, download or reproduce any content or information, software, products or Facility available on Website for any commercial or competitive activity/purpose.yesBus reserves the right to refuse Your access to Website, terminate Your yesBus account, cancel any bus ticket reservations, remove and/or edit content on the Website at any time without notice if we have reasonable belief that Your account is being used for purposes other than as defined under Clause 3(7).</p>
                            <h6>Membership Eligibility:</h6>
                            <p>Use of the Website is available only to persons who can enter into legally binding contracts under the applicable law. Persons who are "incompetent to contract" within the meaning of the applicable law are not eligible to use the Website. If You are a minor, you shall not register as a User of the Website and shall not transact on or use the Website.</p>
                            <p>As a minor if You wish to use or transact on the Website, such use or transactionmay be made by Your legal guardian or parents. We reserve the right to terminate Your membership and/or refuse to provide You with access to the Website if it is discovered that You are a minor or incompetent to contract according to the law.</p>
                            <h6>yesBus Wallet:</h6>
                            <p>yesBus may provide at its sole discretion, the facility to transact through an online yesBus wallet ("Wallet") to its registered users with the following features: Upon cancellation of previously booked tickets, you would be accorded an option of either withdrawing such amounts to your bank account/credit card linked to the account or to the Wallet.</p>
                            <p>The cash equivalent balance available in the Wallet may be used by the Customer for making bus ticket bookings or Hotel bookings or avail any other services available at www.yesbus.in (Website) and yesBus Mobile Application only. The present form and format of the Wallet, issued by yesBus to its Customers, is modelled on a closed system prepaid payment instrument.</p>
                            <p>Any promotional cash credited in to your Wallet by yesBus, will not be refundable into your bank account under any circumstances. On cancellation of a reservation of services provided by yesBus, any utilised promotional cash will be forfeited. This promotional cash has an expiry date beyond which, it will not be available for your usage.</p>
                            <p>The customers are advised to use the amount lying in the Wallet within its validity period. Any amount lying unutilised (except promotional cash deposited by yesBus) beyond a period of three hundred and sixty-five days shall be deemed to be held in trust with the Company. It will be held in trust for a maximum period of one year, after which yesBus will make reasonable efforts to transfer such amount to the beneficiary and if such transfer is not an option, any unclaimed amount will be forfeited. Maximum of 10% of the ticket price can be availed from the wallet promotional cash for ticket booking. yesBus reserves the right to modify such percentage at its sole discretion without any prior intimation.</p>
                            <p>The amount lying in the Wallet are not transferable to any other Wallet of same or different user.</p>
                            <p>The option of keeping the refund amount in Wallet is available only to resident Indians only. Also the amount lying in the Wallets are not available for transactions in foreign currency.</p>
                            <p>By choosing the option to maintain the Wallet with the Company, you agree to comply with the aforesaid terms and conditions.</p>
                            <p>The Company reserves the right to suspend/discontinue Wallet scheme to customers at any time, for any cause, including, but not limited to, for any violation of the Terms and Conditions mentioned herein by the Wallet user or to comply with any rules, regulations, orders, directions, notifications issued by any regulatory authority. The Company makes no express or implied warranty, guarantee, representation or undertaking whatsoever regarding the Services, which are not expressly mentioned herein.</p>
                            <p>The Company may at its sole discretion change, by addition or deletion, these Terms and Conditions.</p>
                            <h6>Privacy:</h6>
                            <p>The 'Privacy Policy' of Website; and The 'Cookie Policy' of Website. Links:</p>
                            <p>This Website may contain links to other websites maintained by third parties ("External Sites"). These External Sites are linked to the Website for Your reference and convenience only. yesBus does not operate, control or endorse in any respect such External Sites or their content. You assume sole responsibility for use of these External Sites and is therefore, advised to examine the terms and conditions of those External Sites carefully.</p>
                            <p>You must not link Your own website or any third party website, to this Website without obtaining the prior written approval of yesBus. Use of any automated screen capture or screen scraping technologies to obtain information from this Website without the prior written approval of yesBus is an infringement of our intellectual property rights.</p>
                            <h6>Usage Conduct:</h6>
                            <p>You acknowledge that You only have a limited right to use the Website and that You have no right to modify any part of the Website or reproduce, copy, distribute, sell, resell or access the Facility for any commercial purposes.</p>
                            <p>You are also under an obligation to use this Website for reasonable and lawful purposes only, and shall not indulge in any activity that is not envisaged in these Terms. You shall use this Website, and any bus tickets purchased through it, for personal, non-commercial use only and shall not re-sell the tickets to any other person.</p>
                            <p>You may not use any meta tags or any other hidden text utilizing the Website or its affiliates' names or trademarks without the express written consent of yesBus and or its affiliates, as applicable.</p>
                            <h6>You must not use the Website for any of the following:</h6>
                            <p>for fraudulent purposes, or in connection with any criminal acts or other unlawful activity; and/or to send or transport in the carriage of the bus, any material that does not belong to You; or material that is illegal, offensive (including but not limited to material that is sexually explicit content or which promotes racism, bigotry, hatred or physical harm), deceptive, misleading, abusive, indecent, harassing, blasphemous, defamatory, libellous, obscene, pornographic, paedophilic or menacing; ethnically objectionable, disparaging or in breach of copyright, trademark, confidentiality, privacy or any other proprietary information or right; or is otherwise injurious to third parties; or relates to or promotes money laundering or gambling; or is harmful to minors in any way; or impersonates another person; or threatens the unity, integrity, security or sovereignty of India or friendly relations with foreign States; or objectionable or otherwise unlawful in any manner whatsoever; or which consists of or contains software viruses, political campaigning, commercial solicitation, chain letters, mass mailings or any "spam” to cause annoyance, inconvenience or needless anxiety.</p>
                            <h6>Correspondence/Notices:</h6>
                            <p>When You use the Website or send emails or other data, information or communication to yesBus, You agree and understand that You are communicating with us through electronic records and You consent to receive communications via electronic means from yesBus periodically and as and when required.</p>
                            <p>We may communicate with You through email/SMS or by such other mode of communication, electronic or otherwise.</p>
                            <h6>Disclaimer of Warranties and Liability:</h6>
                            <p>This Website, all the services (including but not limited to software) included on or otherwise made available to You through this Website are provided on "as is" and "as available" basis without any representation or warranties, express or implied except otherwise specified in writing by yesBus or on the Website. Without prejudice to the forgoing paragraph, Website does not warrant that:</p>
                            <p>Website will be constantly available, or available at all; or The information on this Website is complete, true, accurate or non-misleading.</p>
                            <p>yesBus will not be liable to You in any way or in relation to the contents of, or use of, or otherwise in connection with, the Website. wherever, the same is under the control of the bus operator. The Website does not warrant that this Website; information, content, materials, product (including software) Facility or services included on or otherwise made available to You through the Website; the servers; or electronic communication sent from us are free of viruses or other harmful components, despite our best efforts to prevent these incidents.</p>
                            <p>You are required to enter a valid phone number while booking a bus ticket on the Website. By registering Your phone number with us, You consent to be contacted by us for the purposes of the transaction on the Website or for sending you promotional offers or content.</p>
                            <h6>For the purpose of booking bus tickets through the Website, we warrant that we shall be responsible for the following activities:</h6>
                            <p>Issuing a valid ticket (a ticket that will be accepted by the bus operator); and Providing customer support and information in case of any delays in the departure of a bus / inconvenience caused to You.</p>
                            <p>yesBus will process the ticket cancellation request from customers subject to the cancellation and refund policy of the respective bus operator. The cancellation fee may differ for each bus operator.</p>
                            <h6>However, We DO NOT take any liability for the following:</h6>
                            <p>The bus operator's bus not departing from/reaching the pick-up point or destination on time; The bus operator's employee/s being rude to You; The bus operator's bus seats or the journey not being up to Your expectation;</p>
                            <p>The bus operator modifying and/or cancelling your bus ticket reservation partially or completely due to unavoidable reasons as described in the following clauses; Your baggage getting lost/stolen/damaged;</p>
                            <p>The bus operator changing a customer's seat at the last minute to accommodate a female / child passenger;</p>
                            <p>Bus departing without You boarding the bus, while You were waiting at the wrong boarding point (please call the bus operator to find out the exact boarding point if you are not a regular traveller of a particular bus operator); The bus operator changing the boarding point and/or using a pick-up vehicle at the boarding point to take the passengers to the bus departure point; and The bus operator not permitting Your baggage beyond a limit determined at the bus operator’s sole discretion.</p>
                            <h6>Payment: By the use of any of the payment method/s available on the Website by You, yesBus shall not be responsible or assume any liability, whatsoever in respect of any loss or damage arising directly or indirectly to You due to any of the following:</h6>
                            <p>Lack of authorization for any transaction/s; or Exceeding the preset limit mutually agreed by You and any bank/s; or Any payment issues arising out of the transaction; or Decline of transaction for any other reason/s.</p>
                            <h6>Please note that:</h6>
                            <p>Transactions, transaction price and all commercial terms governing the provision of services are bipartite contractual obligations between You and the bus operator. yesBus acts merely as a facilitator being an independent platform and at no point in time, enters into any direct /contractual arrangement with the customers with regard to the services provided by the bus operator. Use of the payment Facility shall not render yesBus liable or responsible for the non-delivery, non-receipt, non-payment, damage, breach of representations and warranties, or fraud as regards the services listed on the Website.</p>
                            <p>You have specifically authorized the Website or its service providers to collect, process, facilitate and remit payments and/or the transaction price electronically. Your relationship with Website is on a principal to principal basis and by accepting these Terms You agree that Website is an independent contractor for all purposes, and does not have control of or liability for the products or services that are listed on the Website that are paid for by using the payment facility.</p>
                            <p>You understand, accept and agree that the payment facility provided through any third party on the Website is neither a banking nor financial service provided by yesBus, but is merely a facilitator providing an electronic, automated online electronic payment collection and remittance facility for the Transactions on the Website using the existing authorized banking infrastructure and credit card payment gateway networks. Further, by providing payment facility Website is neither acting as a trustee nor acting in a fiduciary capacity with respect to the transaction or the transaction price.</p>
                            <h6>How does the customer cancel a ticket?</h6>
                            <p>Please email on support@yesbus.in for all cancellation related queries. Our customer support team will help you process the request as per the cancellation policy of the bus operator.</p>
                            <h6>Refund Policy:-</h6>
                            <p>Customer will be entitled to a 100% refund of ticket fare if the cancellation of the journey is attributable to either yesBus or the bus operator. Customer will not be entitled to refund for cancellation of tickets for any other reasons.</p>
                            <p>In the event of dishonour of ticket by the bus operator, a full refund of the ticket fare would be processed within seventy two (72) hours. The refund would be credited to the customer within seven (7) working days of the dishonour of ticket. Carriage Policy: You are responsible for ensuring that Your travel date, time and destination are correct before booking/reserving the bus tickets or when you select to confirm your ticket purchase on the Website. Please check the cancellation Policy available on the Website for details about cancellation and refunds. You are solely responsible to be at the designated boarding point reasonably in advance before departure time. We are not liable if you fail to board the bus by the time the bus departs.</p>
                            <p>The bus operators reserve the right to refuse you and your luggage from carriage on the bus for whatsoever reason that they deem justified, including but not limited to: Carrying of contraband, illegal substances, fragile or oversize/overweight luggage, Your unruly behavior, drunkenness or any other behavior that the bus operator deems to affect the other passengers' comfort or safety. You are unable to produce valid government issued identification proof while travelling.The payment of Your fare for the ticket is fraudulent; The booking of Your seat has been done fraudulently or unlawfully or has been purchased from a person not authorized by yesBus; The credit card through which your payment was made for the ticket booking has been reported lost or stolen;</p>
                            <p>The itinerary or booking or confirmation slip is counterfeit or fraudulently obtained;</p>
                            <p>The itinerary of your reservation has been altered by a person other than yesBus or our authorized agent on your express instructions;</p>
                            <p>You are unable to produce a valid and legible boarding ticket for the intended travel. The boarding ticket constitutes as the prima facie evidence of the contract of carriage between the bus operator and the boarding ticket holder;</p>
                            <p>You are deemed medically unfit to travel and may pose a health hazard to other passengers on board the bus, at the absolute discretion of the bus operator;</p>
                            <p>You fail to arrive on time to board before the time of departure or fail to board the bus by the time the bus departs.If the bus operator has refused to carry You or choose to remove You from carriage as per the reasons abovementioned, we reserve the right to cancel the unused ticket and You will not be entitled to further carriage or refund of fare. We will not be liable for the consequential or incidental loss, damage, cost, or fee You may incur due to the refusal of carriage.</p>
                            <p>Children below age twelve (12) will not be allowed to book tickets for themselves nor will be allowed to travel, unless they are accompanied by an adult.</p>
                            <p>Children above the age of 5 years need to compulsorily obtain a bus ticket at the regular fare of the ticket, unless otherwise a particular bus operator specifies otherwise in their terms and conditions.</p>
                            <p>The bus operator reserve the right to reschedule, cancel, and/or delay any journey and substitute other buses for reasons they deem necessary to do so.You are responsible to ensure that valid and necessary government issued personal identification documents, such as the passport, PAN card or Aadhar card, are carried with you for the journey.</p>
                            <h6>Intellectual Property Rights:</h6>
                            <p>Content included on the Website, such as text, graphics, logos, button icons, images, audio clips, digital downloads, data compilations, and software, is the property of yesBus, its affiliates or its content suppliers and is protected by Indian and international copyright and database right laws.</p>
                            <p>The compilation of all content on this Website is the exclusive property of Website and its affiliates and is protected by laws of India and international copyright and database right laws. All software used on this Website is the property of yesBus, its affiliates or its software suppliers and is protected by Indian and international copyright and authors' rights laws. You may not systematically extract/or re-utilise any parts of the contents of the Website without our express written consent. In particular, You may not utilize any data mining, robots, or similar data gathering and extraction tools to extract (whether once or many times) for re-utilisation of any substantial parts of this Website, without yesBus's express written consent. You may also not create and/or publish Your own database that features substantial (eg: prices and service listings) parts of the Website without Rebus's express written consent. You cannot use the yesBus trademarks for commercially exploiting them for Your interests without our express permission. We reserve the right to initiate legal proceedings if we discover any infringement of our intellectual property rights.</p>
                            <h6>Grievance Officer:</h6>
                            <p>In case of any consumer grievance, the End-User may contact yesBus on the email id provided below: support@yesbus.in</p>
                            <h6>Indemnity:</h6>
                            <p>You shall indemnify and hold harmless yesBus, its subsidiaries, affiliates and their respective officers, directors, agents and employees, from any claim or demand, or actions including reasonable attorney's fees, made by any third party or penalty imposed due to or arising out of Your breach of these Terms or any document incorporated by reference, or Your violation of any law, rules, regulations or the rights of a third party. You hereby expressly release yesBus or any of its affiliates or officers and representatives from any cost, damage, liability or other consequence of any of the actions/inactions of the vendors and specifically waiver any claims or demands that you may have in this behalf under any statute, contract or otherwise. Losses: yesBus will not be responsible for any loss (including loss of profits, revenue, contracts, anticipated savings, data, goodwill or wasted expenditure) and any other indirect or consequential loss to You when You commenced using the Website.</p>
                            <h6>Governing law and Jurisdiction:</h6>
                            <p>These Terms are governed by and shall be construed in accordance with the laws of India. The courts in Bengaluru shall have exclusive jurisdiction in respect of any disputes arising out of or in connection with these Terms.</p>
                            <p>Suspension, Termination or Cancellation Website may suspend, cancel or terminate Your account registered with us, with or without prior notice, if we believe in good faith that You have breached any of these Terms or any other terms or policies referred to in these Terms.</p>
                            <p>yesBus reserves the right, at its sole discretion, to accept, hold and/or cancel any reservation of bus tickets made by the You on the yesBus website, yesBus mobile website and yesBus mobile apps, which is deemed to be fraudulent or suspicious by yesBus.</p>
                            <p>yesBus will endeavour to inform You in the event of cancellation of reservation, owing to fraud or suspicious activity, to the best of its capacity, but shall not be liable for a failure to communicate to You of such cancellation.</p>
                            <h6>Amendment / Modification:</h6>
                            <p>yesBus reserves the right to modify these Terms at any time with or without any further notice by uploading the revised Terms on the Website and it is Your duty to keep yourself aware of the revisions to the Terms of the Website.</p>
                            <h6>Limitation Of Liability:</h6>
                            <p>THE WEBSITE/YESBUS WILL NOT BE LIABLE FOR ANY DAMAGES OF ANY KIND, INCLUDING WITHOUT LIMITATION, DIRECT, INDIRECT, INCIDENTAL, PUNITIVE, AND CONSEQUENTIAL DAMAGES, ARISING OUT OF OR IN CONNECTION WITH THE TERMS, SITE, FACILITY, TRANSACTION PROCESSING SERVICE, INABILITY TO USE THE FACILITY OR THE TRANSACTION PROCESSING SERVICE, OR THOSE RESULTING FROM ANY FACILITY PURCHASED OR OBTAINED OR MESSAGES RECEIVED OR TRANSACTIONS ENTERED INTO THROUGH THE WEBSITE, BEYOND THE VALUE OF THE LAST TRANSACTION CARRIED OUT BY YOU THROUGH THE WEBSITE.</p>
                            <h6>Miscellaneous:</h6>
                            <p>The Parties acknowledge and accept that electronic format shall be deemed an acceptable means of communication for the purposes of these Terms. These Terms constitute the complete and entire set of terms between the Parties on the subject of ‘terms of usage of the Website' (save and except the other policies and terms referred to in these Terms) and shall supersede any and all other prior understandings, commitments, representations or agreements, whether written or oral, between the Parties to these Terms. If any provision of these Terms shall be found by any court or administrative body of competent jurisdiction to be invalid or unenforceable, such invalidity or enforceability shall not affect the other provisions of these Terms, which shall remain in full force and effect.</p>
                            <p>In no event will any delay, failure or omission (in whole or in part) in enforcing, exercising or pursuing any right, power, privilege, claim or remedy conferred by or arising under these Terms or by law, be deemed to be or construed as a waiver of that or any other right, so as to bar the enforcement of that, or any other right, power privilege, claim or remedy, in any other instance at any time or times subsequently.</p>
                            
                            <h5>Hotel reservation - Terms and Conditions</h5>
                            <h6>Scope of service:</h6>
                            <p>Yesbus.in (“website/yesBus”)provides a platform to temporary accommodation providers (“Service Providers”)to list their inventory on the website and allows you (“customer/you”)to select and reserve Accommodation, based on the customer’spersonal preferences(“Service”). The term ‘temporary accommodation’ includes but is not limited to hotels, motels, home-stays, hostels and bed & breakfast (collectively “Accommodation(s)”). yesBus acts as an intermediary between the customer and the Service Providers, that have listed their Accommodation on the website, for the purposes of reservation of such Accommodation. A reservation made through the website, istherefore a bipartite contract between the customer and the Service Provider.</p>
                            <p>Therefore, the contractual terms and conditions of the Service Provider shall be applicable once areservation has been made through the website. yesBus only undertakes to share the relevant details of the customer to the Service Provider and a confirmation email on the Service Provider’s behalf to the customer.</p>
                            <p>While rendering the Services, all the information, related to a particular Service Provider, such as the tariff, availability, facilities & amenities, representative images (if any), etc.,of a particular Accommodation, displayed on the website, are attributable to the respective Service Providers themselves and they are fully responsible for updating the same. The website cannot be held liable for any discrepancy in the said information.yesBus does not guarantee the accuracy, completeness, correctness, suitability etc., of such information to the customer and cannot be held responsible in case the information is erroneous, inaccurate, untrue, incomplete, or misleading in nature. The listing of anAccommodation on the website does not constitute and should not be regarded as a recommendation and/or an endorsement of the quality, service level, qualification or rating of any Accommodation made available, to the customer.</p>
                            <p>Pricing and tariff<br/>Accommodation(s) may be available on other websites at tariff lower than those displayed on the website, for any specific period. However, such lower tariff offeredby the Service Provider/s could be subject to specific terms and conditions with respect to refunds, cancellations, etc. customers are advised to check the Accommodation and tariff details, Accommodation policy, booking and cancellation policy, thoroughly before making a reservation of an Accommodation. Obvious errors and mistakes (including but not limited to misprints) are not binding.</p>
                            <p>All special offers and promotions on the website are marked as such.</p>
                            <h6>General terms of use</h6>
                            <p>The primary guest (if it is not the customer), must be atleast 18 years of age, to be able to check-in to an Accommodation.</p>
                            <p>Check-ins and check-outs are subject to Service Providers’ policy and terms. Customers are advised to acquaint themselves with the relevant policies before making a reservation. In case a customer is denied check-in, the website will not be responsible and/or liable for the same.</p>
                            <p>Early check-in or late check-out is subject to availability of Accommodation and the Service Provider may charge you additional tariff, subject to availability and upon Service Provider’s sole discretion. The check-in and check-out timings may change depending on seasonality and the respective Service Provider’s policies.</p>
                            <p>It is mandatory for customer to furnish photo identification at the time of check-in. According to government regulations, government issued photo identification has to be carried by every person above the age of 18 staying at an Accommodation. The identification proof include but not limited to: driving license, voter identification card, passport, or such other documents as the Service Provider may deem fit.Without a valid identification proof, the customer may not be allowed to check-in. Note – customer will note that PAN card will not be accepted as a valid identification proof. Service Provider reserves the right of admission onto their premises. Check-ins may be denied to unmarried/unrelated couples, based on the Service Provider’s policies. Customer and his/her guests posing as a couple may be denied check-in in the absenceof adequate proof of identification, of their marriage. yesBus will be not be responsible for denial of check-in in such instances.</p>
                            <p>Therefore, customer agrees no refund can be claimed from the website, in such circumstance(s). The reservation for stay at an Accommodation may not include additional services such as laundry, telephone facilities, meals that are not included in a particularreservation, room service etc., unless expressly stated in the respective Service Provider’s policies. The Service Providers will charge the customer for availing such additional facilities, at the time of check-out. Service Provider may charge a mandatory meal surcharge during festive periods such as Christmas New Year's Eve or other festivals as per the policies of such Service Provider. Customer will have to settle the dues for availing any such additionalservices (including mandatory meal surcharges) with the Service Provider, at the time of check-out. Service tax is applicable on renting of temporary Accommodation. Therefore, the Service Provider may charge the same from the customer, in addition to the tariff displayed on the website. All the timings mentioned on the website are in Indian Standard Time, unless mentioned otherwise.</p>
                            <h6>Specific terms relating to special offers, discounts etc. : </h6>
                            <p>yesBus may provide, from time to time, at its sole discretion, special discount offers (“Offers”) on Accommodation reservation to its customers, that are applicable for a duration of time as provided in such Offers. With regards the above abovementioned Offers, yesBus reserves the right to: discontinue and/or extend any Offers, without assigning any reasons whatsoever, and without prior notice to the customer. change/ amend/ add/ delete/ modify terms and conditions of any such Offers without assigning any reasons whatsoever, without prior notice to the customer. Customer acknowledges that he/she will not be personally notified of any such change/ amendment / addition /deletion/ modification to the Offer. Customer is advised to check for any such change/ amendment / addition /deletion/ modification regularly.</p>
                            <p>Customer hereby unconditionally agrees to all such changes/ amendments/ additions/deletions/ modifications. The Offers will be applicable only on bookings made through www.yesbus.in.</p>
                            <p>Offer discounts may vary from one Accommodation to another. Customer is advised to verify the applicable Offered on a particular Accommodation, before applying the offer code.</p>
                            <p>Offers are applicable only on the base fare of the tariff, unless stated otherwise. One Offer cannot be combined with any other Offer(s), unless stated otherwise.</p>
                            <p>Offer is valid only for the reservations made by the customer for his/her personal use, or for reservations made for his/her family. Offer is not valid for bookings made by travel agents. yesBus reserves the right, without prior notice to the customer, to cancel reservations, which are not made in compliance with this condition. In addition to the general terms and conditions mentioned herein, Offers may have other terms and conditions specific to such Offer. The terms and conditions may include restrictions regarding cancellation and refund. Customer is advised to get accustomed, acknowledge and accept the same before availing any discounts under any Offers. Availing of an Offer is at the discretion of the customer. All taxes, duties, levies and/or charges payable in connection with the benefits accruing under such special offers (if any), shall be borne solely by the customer, and yesBus shall not be liable in any manner whatsoever for payment of any such taxes, duties, levies and/or charges. Cancellation policy.</p>
                            <p>The cancellation policy (“Cancellation Policy”) displayed on our website is very dynamic and may change in the duration between the date of making reservation and the date of customer’s stay at the Accommodation. The Cancellation Policy prevailing at the time of check-in/cancellation of the reservation by the customer will be the applicable policy. Once a reservation is made, the customer agrees to the terms and conditions of the Service Provider, including those related to cancellation and refunds. The Service Provider may have its own cancellation policy.</p>
                            <p>The general cancellation and no-show policy of each the respective Service Provider is made available on the website on the Accommodation information page, and displayed during the time when the customer makes the reservation and may also be sent to the customer, in the email confirming the reservation. For cancellation of reservation(s) made after availing any Offer mentioned in heading IV of these Terms, the cancellation charges are calculated on total price before the application of Offer.</p>
                            <p>For such reservations, an additional cancellation charge, which will be deducted from the refund amount, after making all the deductions in accordance with the Service Provider’s cancellation policy. For cancellation of a reservation, the customer shall make a verbal and/or a written request to our customer care representatives or make such cancellation through the website or the yesbus mobile application.</p>
                            <p>Only those cancellation requests that are communicated through phone or via email to our customer support team, or through our website or mobile application, shall be entertained. yesBus shall not be liable to entertain any cancellation requests made directly to the Service Provider without intimating yesBus in writing or over the phone call. If the customer or his/her guests do not turn up at the Accommodation (“No-Show”), pursuant to making a reservation, the customer shall continue to be liable for the entire tariff displayed at the time of making the reservation, and the Service Provider may debit the amount from the customer’s credit card directly. Refunds & modifications to reservations</p>
                            <p>yesBus does not facilitate any changes and/or modifications to reservations once they are confirmed.</p>
                            <p>All the refunds shall be made by the Service Provider directly to the customer and yesBus is not responsible and/or liable to facilitate any refunds. Customer’s bank may debit convenience or such other charges, as the bank may deem appropriate, from refunds you may be entitled to, pursuant to cancellation of the reservation.</p>
                            <h6>Content of Service Provider:</h6>
                            <p>An authorized agent of the Service Provider may provide information about a listed Accommodation, and/or upload images of the Accommodation listed on the website (“Content”), and display such images on the website. Customers may view such images before making a reservation. Users should keep in mind the following things while viewing the Content: Accommodation reviews, reports, images, videos, and any related Content which may be displayed on the website originate from third parties and represent the opinion of the respective third party, and yesBus does not endorse, or validate or confirm the authenticity of such Content, and uses such Content at his/her own sole risk. Customer accepts and agrees that there could be a lapse of time between the posting, transmission, or uploading of such Content and when the customer would have viewed such Content, that the Content could be outdated.</p>
                            <h6>Customer ratings and reviews:</h6>
                            <p>Users of this website may post reviews, comments and other content; send communications; and submit suggestions, comments, questions, or other information, as long as the content is not illegal, obscene, abusive, threatening, defamatory, invasive of privacy, infringing of intellectual property rights, or otherwise injurious to third parties, or objectionable and does not consist of or contain software viruses, political campaigning, commercial solicitation, chain letters, mass mailings, or any form of "spam." In the event a customer uses a false e-mail address, impersonates any person or entity, or otherwise misleads as to the origin of any content, yesBus is entitled to delete such details, and take necessary steps. yesBus reserves the right (but not the obligation) to remove, refuse, delete or edit any content that in the sole judgment of yesBus violates these Terms and Conditions, and/or terminate the customer’s permission to access or use this website.</p>
                            <h6>If you do post content or submit material, and unless we indicate otherwise, you:</h6>
                            <p>(a) grant yesBus and its affiliates a non-exclusive, royalty-free, irrevocable, perpetual and fully sub-licensable rights to use, reproduce, modify, adapt, publish, translate, create derivative works from, distribute, and display such content throughout the world in any media; and</p>
                            <p>(b) yesBus and its affiliates and sub-licensees the right to use the name that you submit in connection with such content, if they choose.</p>
                            <p>You agree that the rights you grant above are irrevocable during the entire period of protection of your intellectual property rights associated with such content and material. You agree to waive your right to be identified as the author of such content and your right to object to derogatory treatment of such content. You agree to perform all further acts necessary to perfect any of the above rights granted by you to yesBus, including the execution of deeds and documents, at its request.</p>
                            <p>You represent and warrant that you own or otherwise control all of the rights to the content that you post or that you otherwise provide on or through the website; that, as at the date that the content or material is submitted to yesBus: (i) the content and material is accurate; (ii) use of the content and material you supply does not breach any applicable yesBus’ policies or guidelines and will not cause injury to any person or entity (including that the content or material is not defamatory); (iii) the content is lawful. You agree to indemnify yesBus and its affiliates for all claims brought by a third party against it or its affiliates arising out of or in connection with a breach of any of these warranties.</p>
                            <p>The Accommodation star ratings are provided for your information only. The starratings are based on information we have received from our Service providers,including the Accommodations. We do not independently verify star ratings. A starrating is only indicative of the level of comfort you can expect to find in a particularAccommodation, with a higher star rating generally being indicative of a higher levelof comfort and amenities. Please be aware that star ratings can vary greatly on thebasis of location, available alternatives, local market conditions, practices or othercircumstances. Thus, it is possible that a star rating in one particular state or city isnot comparable to the same star rating in another country or city due to suchcircumstances. Star ratings do not necessarily reflect all the amenities or services thatmight be available at an Accommodation, and some amenities and features may notbe available in every room or at every property with a particular star rating. If thereare particular amenities or features that are important to you, please contact our customer service and inquire as to the availability of such amenities or features.</p>
                            <p>PLEASE BE AWARE THAT BY DISPLAYING INFORMATION ABOUT ASERVICE PROVIDER, YESBUS IS NOT SUGGESTING THAT, AVAILINGTHEIR SERVICES IS ADVISABLE OR RISK FREE. YESBUS IS NOTLIABLE FOR ANY DAMAGES AND/OR LOSSES THAT MAY RESULTFROM THE CUSTOMER DECIDING TO AVAIL SUCH SERVICES. ANYSUCH RESERVATION MADE BY THE CUSTOMER IS AT THECUSTOMER’S SOLE RISK BASIS THE CUSTOMER’S INDEPENDENTJUDGEMENT.</p>
                            <p>ypool - Terms and Conditions<br/>ypool is a feature on the yesBus app that connects people who travel on a common route using their private vehicles. The purpose of ypool is to promote carpooling between individuals commuting using their private vehicles, reduce traffic and thereby work towards the greater cause of reducing pollution.</p>
                            <h6>GENERAL TERMS Definitions:</h6>
                            <p>‘User’ shall have the same meaning as provided under the User Agreement. ‘Driver’ refers to a User who offers his/her private vehicle for carpool. ‘Rider’ refers to a User who would like to avail the carpool service being offered by a Driver. ‘Trip’ refers to a ride provided by a Driver or utilized by a Rider as per the terms mentioned under hereunder. ‘ypool Points’ refers to points credited to a Driver from a Rider for a given Trip.</p>
                            <p>‘ypool Wallet’ refers to wallet provided by yesBus to each of its User(s) to make or receive payment for a given Trip. As defined above payment will be strictly made in ypool Points only.</p>
                            <p>‘TMW Petrol Card’ refers to “The Mobile Wallet Petrol Card”, for which the Rider or Driver would need to separately enroll yesBus reserves its rights to amend the Terms of Service, at any time, by updating the amended terms on the Website. As a process, it would be imperative upon the User to accept the revised Terms of Service, in absence of which they may not be allowed to use ypool. The revised Terms of Service will be considered effective as and when they are posted on the Website.</p>
                            <h6>ROLE OF YESBUS</h6>
                            <p>ypool merely provides a platform to connect a Rider with a Driver, basis the common coordinates or route on which they may travel.</p>
                            <p>ypool is neither a transportation carrier nor provides the service of transportation in any form or manner.</p>
                            <p>ypool doesn’t guarantee the delivery of service between a Driver and a Rider. It is the sole discretion of the Driver to decide whether to offer a Trip to a Rider or not. There is no commercial relation between any User and ypool.</p>
                            <p>ypool has no role to play in deciding the route of a Trip and destination points. It is pre-determined between a Driver and a Rider. ypool is merely a platform that allows User to carpool together entirely for non-commercial purpose. These trips shall not constitute a “hire” of a Driver’s vehicle by a Rider nor provide a “reward” to the Driver in any manner. ypool shall not be liable qua the information provided by a Driver or a Rider. Neither shall ypool be liable with respect to quality of the vehicle or any other factors with respect to the Ride. USER OBLIGATIONS Users agree that: They will always abide by the terms mentioned under the instant Terms of Service.</p>
                            <p>Any breach will lead to suspension of User account with immediate effect. They will not indulge into any activity which is illegal under any law. They shall treat other Users during a trip respectfully and shall not indulge into any act or omission which is not permitted under law. They shall provide sufficient proof of identity to the other User whenever sought by them in addition to that available through ypool.</p>
                            <p>They shall reach the mutually agreed meeting point on the agreed time. In case, if any User fails to adhere the same, the other User shall have the right to cancel the Trip without any extra cost or liability.</p>
                            <p>They shall not discriminate or harass anyone on the basis of race, national origin, religion, gender, gender identity, physical or mental disability, medical condition, marital status, age or sexual orientation.</p>
                            <h6>OBLIGATIONS OF A DRIVER</h6>
                            <p>Driver must submit all necessary documents including driving license, RC, insurance etc. alongwith any other relevant document required for KYC check in place while enrolling for ypool. Driver shall not provide the Trip for the purpose of hire or reward. Doing so will lead to delisting the Driver from ypool and may also attract prosecution under applicable laws.</p>
                            <p>The Driver shall not charge any amount in addition to the ypool Points as may be applicable for each Trip. The aforesaid ypool Points are designed to cover only the proportionate costs that may be incurred by a Driver for each Trip. The Driver shall keep all necessary documents including but not limited to driving license, RC, insurances, pollution under control certificate etc. in the vehicle at the time of the Trip. Driver also undertakes to present the same to the Rider if a Rider shows an intent to verify the same. The Driver will be solely responsible for any and all liability which results from or is alleged as a result of the operation of the vehicle during such Trip, including but not limited any legal liability, accidents, injuries, death etc.</p>
                            <p>Driver will not offer rides to any other person while offering a ride to an ypool User and will use the vehicle that has been registered by the Driver with ypool or if the Driver is ferrying anyone other than the Rider(s), the Driver shall provide full disclosure of other persons travelling in the vehicle to the Rider. Driver shall report in the event: the Rider is different from the one enlisted for pickup. of any suspicious behavior by any of the Rider. Rider violates any law laid down by central or state government. OBLIGATIONS OF A RIDER Rider shall provide all necessary documents required for KYC check while registering for ypool. Rider shall compensate the Driver with the ypool Points for availing a given Trip, immediately after completion of the same. to respect Driver, his/her vehicle or any other property of the Driver with due care and diligence. Rider shall report in the event: the Driver of the vehicle is different from the one registered on ypool. The vehicle is different than the one registered by the Driver on ypool.</p>
                            <p>The Driver violates any traffic rules, any other lawas or demands any additional compensation from the Rider. If there are any other passengers in the vehicle, not registered with ypool.</p>
                            <h6>PRICING AND PAYMENT TERMS</h6>
                            <p>ypool works on a points system, whereby, points are exchanged between the Driver and the Rider for a given Trip basis the ypool points agreed upon between both Driver and Rider for the distance travelled. ypool has a capping on fixation of maximum ypool points for every kilometer to be travelled on a given Trip. The maximum ypool Points that can be charged by a Driver from a Rider is as follows:</p>
                            <p>Category of Vehicle ypool Points/km Hatchback 5 Sedan 6 MUV/SUV 7 However, the Driver is at liberty to offer a Trip at lowers points than mentioned above.ypool does not charge any fee from the User(s) currently. However, ypool may charge a service fee from the User(s) in future to cover operational expenditure.</p>
                            <p>Cash payment is strictly prohibited. Neither Driver(s) nor Rider(s) are permitted to make or accept payments in cash under any circumstances.</p>
                            <p>Rider is required to recharge its ypool Wallet choosing the mode of recharge i.e. debit card, credit card, net banking, e-wallet etc. As a process, at the end of each Trip, ypool Wallet of a Rider will be automatically debited with the points for that Trip and be credited to the Driver’s ypool Wallet.</p>
                            <p>For any payment or other related issues, User may write to us at ypool@yesbus.in</p>
                            <p>Driver can redeem the ypool Points available in its ypool Wallet for Amazon vouchers.</p>
                            <p>Driver will also have an option to donate the ypool Points to MakeMyTrip Foundation. MakeMyTrip Foundation is a public charitable trust setup with an aim to work towards sustainable tourism while encouraging responsible tourism, ensuring environmental sustainability and undertaking projects towards restoration and development.</p>
                            <p>The conversion rate for 1 ypool Point is 1 Rupee. yesBus do not charge any fee for conversion of ypool Points.</p>
                            <p>The promotional/bonus ypool Points credited to any User’s ypool Wallet will be strictly for the purposes of availing carpool services on yesBus. User will not be allowed to redeem or seek refund of these ypool Points.</p>
                            <h6>LIMITATION OF LIABILITY</h6>
                            <p>ypool merely provides a platform to Riders and Drivers to connect with each other for a private carpool service and is not liable for any loss or damage that the User may incur while availing or providing the same.</p>
                            <p>Though the Driver(s) and Rider(s) registering on ypool go through a KYC process, they are also required to perform due diligence to ensure that the information provided on ypool matches a Driver or a Rider.</p>
                            <p>User shall indemnify yesBus against any such loss, damage, claims etc. that may be incurred by yesBus due to the acts or omission of a User.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </>
  )
}

export default TermAndCondition
import React from 'react'
import styled from 'styled-components';
import {faBell, faEnvelope, faPhone } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon} from '@fortawesome/react-fontawesome';
const Notification = () => {
  return (
    <>
        <HomeNotication>
            <div className='w-65'>
                {/* <FontAwesomeIcon className='bellblink' icon={faBell}/> */}
                <marquee>
                {/* Lorem, ipsum dolor sit amet consectetur adipisicing elit. Molestiae, ea! Quasi vel, earum exercitationem ipsa autem temporibus deleniti corporis. Atque ut repellendus reiciendis? Quo repellendus, hic odit aut blanditiis aspernatur. */}
                </marquee>
            </div>
            <div className='w-35'>
                <div>
                    <span><FontAwesomeIcon icon={faPhone}/></span> 
                    +91 8434-50-77-07
                </div>
                <span>|</span>
                <div>
                    <span><FontAwesomeIcon icon={faEnvelope}/></span>
                    info@yesbus.in
                </div>
            </div>
            
        </HomeNotication>
    </>
  )
}
const HomeNotication = styled.div`
    background : rgb(46 49 136);
    display:flex;
    align-items: center;
    justify-content: space-around;
    color: #fff;
    padding: 3px 0px;
    svg{
        padding-right: 6px;
    }
    .w-65{
        flex-basis: 65%;
        display: flex;
        align-items: center;
        marquee{
            color: #c8473b;
            font-weight: 500;
        }
        .bellblink {
            animation: blinker 1s linear infinite;
        }

        @keyframes blinker {
            20% {
                color: red;
            }
            50% {
                opacity: 0;
                color: #fff;
            }
        }
    }
    .w-35{
        display: flex;
        align-items: center;
        gap: 30px;
        font-size: 14px;
        span{
            color: #a1a1a1;
        }
    }
    @media (max-width: 767px) {
        .w-65{
            flex-basis: 90%;
        }
        .w-35{
            display:none;
        }
    }
    @media (min-width: 768px) and (max-width: 991px) {
        .w-65{
            flex-basis: 95%;
        }
        .w-35{
            display:none;
        }
    }
    
`;

export default Notification
import React from 'react'

const CancellationAndRefundPolicy = () => {
  return (
    <>
        <section class="terms_and_cond">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-lg-12 col-md-12">
                        <div class="terms_and_cond_main privay_policy">
                            <h2>Cancellation & Refund Policy</h2>
                            <h6>Cancellation policy</h6>
                            <p>YesBus will process the ticket cancellation request from You as per the cancellation/refund policy of the bus operator. The cancellation fee and the period taken to process the refund will differ for each bus operator. Please refer to the bus operator's cancellation policy. How does the customer cancel a ticket?</p>
                            <p>Please Email us at contact@yesbus.in for all cancellation related queries. Our customer support team will help you process the request as per the cancellation policy of the bus operator.</p>
                            <h6>Refund Policy</h6>
                            <p>Customer will be entitled to a 100% refund of ticket fare if the cancellation of the journey is attributable to either yesBus or the bus operator. Customer will not be entitled to refund for cancellation of tickets for any other reasons. No service charges/Tax if any will be refunded in case of cancel by the passanger.</p>
                            <p>In the event of dishonour of ticket by the bus operator, a full refund of the ticket fare would be processed within seventy two (72) hours. The refund would be credited to the customer within seven (7) working days of the dishonour of ticket.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </>
  )
}

export default CancellationAndRefundPolicy
import React, {useState} from 'react';
import { Link } from 'react-router-dom';
import styled from "styled-components";
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.js';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import { faLock } from '@fortawesome/free-solid-svg-icons';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { faArrowCircleLeft } from '@fortawesome/free-solid-svg-icons';
import Register from './Register';
import Login from './Login';


const Header = () => {
    const [loginPopup, setLoginPopup] = useState(false);
    const [signinPopup, setSignInPopup] = useState(false);
    const [forgotPasswordPopup, setForgotPasswordPopup] = useState(false);
    const [sideMenuPopup,setSideMenuPopup] = useState(false);
    
  return (
    <>
        <Login signinPopup={signinPopup} setSignInPopup={setSignInPopup} loginPopup={loginPopup} setLoginPopup={setLoginPopup} forgotPasswordPopup={forgotPasswordPopup}  setForgotPasswordPopup={setForgotPasswordPopup}  />
        <LoginSideBar style={loginPopup===false && forgotPasswordPopup===true ? {display:''} : {display:'none'}} className='sidebar_sec'>
            <div className='modal-down-bg-sec'></div>
            <div className="modal-login-bg-sec">
                <div className="login_signup_menu">
                    <div className="login_signup_menu_close new_close_round">
                        <Link onClick={()=>setForgotPasswordPopup(false)}>
                        <FontAwesomeIcon icon={faTimes} />
                        </Link>
                    </div>
                    <div className='title_head'>
                        <h6>Forgot Password?</h6>
                        <h5>Enter your Email Id to get the reset password link</h5>
                    </div>
                    <div className='login_pass_sec'>
                        <form action="">
                            <div className='form_input_sec'>
                                <span className="passwrod_sec_inner_cont_input span_of_input">
                                    <FontAwesomeIcon icon={faEnvelope} />
                                    <input type="text" name="password" className="" placeholder="Enter Your Email"/>
                                    <span className="incorrect_number_requierd login-error" id="login_username_error" style={{display:'none'}}></span>
                                </span>
                            </div>
                            <div className="login_btns_sec">
                                <ul>
                                    <li>
                                        <button type="submit" className="login_btn">Send Link</button>
                                    </li>
                                </ul>
                            </div>                            
                            <div className="create_acc_sec" style={{textAlign:'left'}}>
                            <FontAwesomeIcon style={{color:'rgb(45, 49, 141)'}} icon={faArrowCircleLeft} /> <Link onClick={()=>{setForgotPasswordPopup(false);setLoginPopup(true)}}>Back To Login</Link>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </LoginSideBar>
        {
            sideMenuPopup === true && 
            <>
                <RightMenuBar className='sidebar_sec'>
                    <div className='modal-down-bg-sec'></div>
                    <div className="modal-login-bg-sec">
                        <div className="login_signup_menu">
                            <div className="login_signup_menu_close new_close_round">
                                <Link onClick={()=>setSideMenuPopup(false)}>
                                    <FontAwesomeIcon icon={faTimes} />
                                </Link>
                            </div>
                            <div className='login_pass_sec'>
                                <ul className='menus_list'>
                                    <li>
                                        <Link to="/" onClick={()=>setSideMenuPopup(false)}>HOME</Link>
                                    </li>
                                    <li>
                                        <Link to="/about-us" onClick={()=>setSideMenuPopup(false)}>About</Link>
                                    </li>
                                    <li>
                                        <Link to="/contact-us" onClick={()=>setSideMenuPopup(false)}>CONTACT</Link>
                                    </li>
                                    <li>
                                        <Link to="/cancel-ticket" onClick={()=>setSideMenuPopup(false)}>CANCEL TICKET</Link>
                                    </li>
                                    <li>
                                        <Link to="/find-ticket" onClick={()=>setSideMenuPopup(false)}>FIND TICKET</Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </RightMenuBar>
            </>
        }
  
        <Register signinPopup={signinPopup} setSignInPopup={setSignInPopup} loginPopup={loginPopup} setLoginPopup={setLoginPopup} />

        <HeadSection className="new_header_pre_login no-print">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-12 col-md-12 desk_mode">
                        <div className="new_header_pre_login_inner">
                            <div className="row">
                                <div className="col-lg-2 col-md-2">
                                    <div className="new_header_pre_login_inner_logo">
                                        <Link to="/"><img src={process.env.PUBLIC_URL + '/images/yesbuslogo.png'} alt="yesbus" title="yesbus" /></Link>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6">
                                    <div className="new_header_pre_login_inner_menu_sec">
                                        <ul>
                                            <li>
                                                <Link to="/">Home</Link>
                                            </li>
                                            <li>
                                                <Link to="/about-us">About</Link>
                                            </li>
                                            {/* <li>
                                                <a href="">Manage Booking</a>
                                            </li> */}
                                            {/* <li>
                                                <a href="">Car Hire</a>
                                            </li> */}
                                            <li>
                                                <Link to="/contact-us">CONTACT</Link>
                                            </li>
                                            <li>
                                                <Link to="/cancel-ticket">CANCEL TICKET</Link>
                                            </li>
                                            <li>
                                                <Link to="/find-ticket">FIND TICKET</Link>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-4 loginsec">
                                    <Link className="join_now_btn" id="joinBtn" onClick={()=>setLoginPopup(true)} >Login/Sign Up</Link>
                                    <a className="download_app_btn" id="joinBtn" href="">Download APP</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="new_header_pre_login_inner mob_mode">
                    <div className="yb_logo_sec">
                        <div className="new_header_pre_login_inner_logo">
                            <Link to="/"><img src={process.env.PUBLIC_URL + '/images/yesbuslogo.png'} alt="yesbus" title="yesbus"/></Link>
                        </div>
                    </div>
                    <Link className="download_app_btn hideformob" id="joinBtn" href="" onClick={()=>setLoginPopup(true)}>Login/Sign Up</Link>
                    <div className="all_head_right right_icn">
                        <Link id="showmenu1" className="right_arrow_img new_remove" onClick={()=>setSideMenuPopup(true)}>
                            <img src="/images/bl-arrow-new.png" alt=""/>
                        </Link>
                    </div>
                </div>
            </div>
        </HeadSection>
        
    </>
  )
  
}

const HeadSection = styled.header`
    position: relative;
    background: #e9ecef;
    ${'' /* box-shadow: 0px 2px 12px #00000029; */}
    box-shadow: rgb(0 0 0 / 55%) 0px 24px 39px;
    padding: 10px 0;
    margin-bottom: 0px;
    z-index: 999;
    .container-fluid {
        padding-left: 100px;
        padding-right: 100px;
    }
    
    .new_header_pre_login_inner {
        position: relative;
        .row{
            display: flex;
            align-items: center;
            .loginsec{
                text-align:right
            }
        }
        .new_header_pre_login_inner_logo {
            position: relative;
            img {
                height: 52px;
            }
        }
        .new_header_pre_login_inner_menu_sec {
            position: relative;
            ul {
                display: flex;
                text-align: right;
                align-items: center;
                justify-content: center;
                align-items: flex-end;
                li:first-child {
                    margin-right: 0px;
                }
                li {
                    display: inline-block;
                    margin-left: 40px;
                    a {
                        display: inline-block;
                        color: #000000;
                        font-weight: 600;
                        font-size: 17px;
                        text-transform: uppercase;
                        position: relative;
                    }
                }
            }
        }
        .join_now_btn {
            display: inline-block;
            font-weight: 600;
            text-transform: uppercase;
            position: relative;
            background: #2d318d;
            align-items: center;
            justify-content: center;
            color: #fff;
            border-radius: 5px;
            padding: 7px 25px;
            font-size: 14px;
            transition: 0.5s ease-in-out;
            border: 1.5px solid #2d318d;
        }
        .join_now_btn:hover {
            background: #fff;
            color: #2d318d;
        }
        .download_app_btn {
            display: inline-block;
            margin-left: 5px;
            font-weight: 600;
            text-transform: uppercase;
            position: relative;
            background: #fff;
            align-items: center;
            justify-content: center;
            color: #2d318d;
            border-radius: 5px;
            padding: 7px 25px;
            font-size: 14px;
            transition: 0.5s ease-in-out;
            border: 1.5px solid #2d318d;
        }
        .download_app_btn:hover {
            background: #2d318d;
            color: #fff;
        }
        
    }
    /* Styles for screens smaller than 768px */
    @media (max-width: 767px) {
    /* Add your styles here */
        .desk_mode{
            display :none;
        }
        .container-fluid {
            padding-left: 10px;
            padding-right: 10px;
        }
        .new_header_pre_login_inner{
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
    }
    @media (max-width: 767px) {
        .hideformob{
            display:none !important;
        }
    }

    /* Styles for screens between 768px and 991px */
    @media (min-width: 768px) and (max-width: 991px) {
    /* Add your styles here */
        padding: 3px 25px;
        .desk_mode{
            display :none;
        }
        .container-fluid {
            padding-left: 10px;
            padding-right: 10px;
        }
        .new_header_pre_login_inner{
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
        .w-35{
            gap: 10px;
        }
        .w-65{
            flex-basis: 55%;
        }
    }

    /* Styles for screens larger than 992px */
    @media (min-width: 992px) {
    /* Add your styles here */
        .mob_mode {
            display: none;
        }
    }
    @media (min-width:250px) and (max-width:390px) {
        .new_header_pre_login_inner .download_app_btn{
            padding:5px;
            font-size: 12px;
        }
        .banner_label h1{
            font-size: 16px;
        }
    }
`;
const LoginSideBar = styled.div`
    position: fixed;
    top: 0px;
    right: 0px;
    width: 100%;
    height: 100vh;
    ${'' /* background-color: #fff; */}
    z-index: 9999;
    display: grid;
    grid-template-columns: 2fr 1fr;
    .modal-down-bg-sec {
        background: #0000004a;
    }
    .modal-login-bg-sec {
        background: #fff;
        .login_signup_menu{
            padding: 10px 30px 0px 30px;
            .new_close_round{
                font-size: 30px;
                svg{
                    color: #000;
                }
            }
            .title_head {
                h6{
                    text-align: center;
                    color: #2d318d;
                    font-weight: 500;
                    font-size: 22px;
                    margin-bottom: 10px;
                }
                h5 {
                    position: relative;
                    font-size: 27px;
                    font-weight: 300;
                    line-height: inherit;
                    margin-bottom: 30px;
                }
            }
            .login_pass_sec{
                form{
                    .form_input_sec{
                        padding-top: 20px;
                        .span_of_input{
                            position: relative;
                            .login_password{
                                margin-top: 20px;
                            }
                            svg{
                                position: absolute;
                                left: 0;
                                top: 0;
                                z-index: 1;
                                color: #2d318d;
                                font-size: 22px;
                                border-right: 1.5px solid #ccc;
                                padding-right: 15px;
                            }
                            input{
                                position: relative;
                                width: 100%;
                                height: 42px;
                                border: none;
                                border-bottom: 1.5px solid #2d318d;
                                color: #000000;
                                font-size: 18px;
                                font-weight: 400;
                                padding-left: 50px !important;
                            }

                        }
                    }
                    .remeber_check_sec {
                        position: relative;
                        margin: 18px 0 0 0;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        .container {
                            display: block;
                            position: relative;
                            padding-left: 30px;
                            margin-bottom: 0px;
                            cursor: pointer;
                            font-size: 18px;
                            -webkit-user-select: none;
                            -moz-user-select: none;
                            -ms-user-select: none;
                            user-select: none;
                            font-weight: 400;
                            color: #000;
                            input {
                                position: absolute;
                                opacity: 0;
                                cursor: pointer;
                                height: 0;
                                width: 0;
                            }
                            .checkmark {
                                position: absolute;
                                top: 3px;
                                left: 0;
                                height: 18px;
                                width: 18px;
                                background-color: transparent;
                                border: 1px solid #000;
                                border-radius: 4px;
                            }
                        }
                    }
                    .login_btns_sec{
                        padding-top: 30px;
                        text-align: center;
                        .login_btn{
                            display: inline-block;
                            font-weight: 600;
                            text-transform: uppercase;
                            position: relative;
                            background: rgb(45, 49, 141);
                            -webkit-box-align: center;
                            align-items: center;
                            -webkit-box-pack: center;
                            justify-content: center;
                            color: rgb(255, 255, 255);
                            border-radius: 5px;
                            padding: 7px 25px;
                            font-size: 14px;
                            transition: all 0.5s ease-in-out 0s;
                            border: 1.5px solid rgb(45, 49, 141);
                        }
                        .login_btn:hover{
                            background: #fff;
                            color: rgb(45, 49, 141);
                        }
                    }
                    .or_sec_text {
                        position: relative;
                        padding-left: 0px;
                        margin: 30px 0 0px 0;
                        text-align: center;
                        span {
                            font-size: 20px;
                            font-weight: 500;
                            color: #8b8b8b;
                            margin: 10px 0 15px 0;
                            position: relative;
                        }
                        span:before {
                            position: absolute;
                            content: '';
                            right: 40px;
                            top: 13px;
                            height: 1.5px;
                            width: 80px;
                            background: #8b8b8b;
                        }
                        span:after {
                            position: absolute;
                            content: '';
                            left: 40px;
                            top: 13px;
                            height: 1.5px;
                            width: 80px;
                            background: #8b8b8b;
                        }
                    }
                    .login_diff_sec{
                        text-align: center;
                        padding-top: 20px;
                        img{
                            width: 50%;
                        }
                    }
                    .create_acc_sec{
                        padding-top: 40px;
                        font-size: 18px;
                        text-align: center;
                        a{
                            font-weight: 600;
                        }
                    }
                }
            }
        }
    }

    @media (max-width: 767px) { 
        grid-template-columns: 1fr;
        
    }
    @media (min-width: 768px) and (max-width: 991px) {
        grid-template-columns: 1fr 2fr;
    }
`;
const RightMenuBar = styled.div`
    position: fixed;
    top: 0px;
    right: 0px;
    width: 100%;
    height: 100vh;
    z-index: 99999;
    display: grid;
    grid-template-columns: 2fr 1fr;
    .modal-down-bg-sec {
        background: rgb(0 0 0 / 42%);
    }
    .modal-login-bg-sec {
        background: #fff;
        .login_signup_menu{
            padding: 10px 20px 0px 20px;
            .new_close_round{
                font-size: 30px;
                svg{
                    color: #dc3545;
                }
            }
            .login_pass_sec{
                ul.menus_list{
                    padding-top: 20px;
                    li{
                        padding-bottom: 20px;
                        font-size: 16px;
                        font-weight: 600;
                        a{
                            color: #000;
                        }
                    }
                }
            }
        }
    }

    @media (max-width: 767px) { 
        grid-template-columns: 1fr 2fr;
        
    }
    @media (min-width: 768px) and (max-width: 991px) {
        grid-template-columns: 1fr 2fr;
    }
`;
export default Header
import React,{useState,useEffect} from 'react'
import axios from 'axios'
import { Link } from 'react-router-dom';

const TopRoutes = () => {
  const [routes, setRoutes] = useState([]);
  const [seeMoreRoutes, setSeeMoreRoute] = useState(false);
    // get routes from api using axios
    const apiUrl = process.env.REACT_APP_API_URL;
    const apiKey = process.env.REACT_APP_API_KEY;
    const apiToken = process.env.REACT_APP_API_TOKEN;
    useEffect(() => {
        axios.get(apiUrl+'/master/all-routes', { 
            headers: { 
              'YESBUS_API_KEY': apiKey,
              Authorization: `Bearer ${apiToken}`,
            }
        })
        .then(res => {
          if(res.data.status==200){
            setRoutes(res.data.data);
          }else{
            setRoutes([]);
          }
        })
        .catch(err => {
            console.log(err);
            setRoutes([]);
        })
    }, []);
  return (
    <>
        <section className="top-routs top-operators">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-6">
                <h3 className="title-section">Poupular Routes</h3>
              </div>
              {
                routes.length>12 &&
                <div className="col-md-6 text-right all_routes">
                  <Link routerlink="routes" className="border-btn bh" href="/routes" onClick={()=>setSeeMoreRoute(!seeMoreRoutes)}>
                    {
                      seeMoreRoutes==false ? 'See All Routes' : 'See Less Routes'
                    }
                  </Link>
                </div>
              }
              <div className="col-md-12">
                <ul>
                  {
                    routes.map((route, index) => (
                      <li key={index} style={index>11 && seeMoreRoutes==false ? {display:'none'} : {display:''}}>
                        {/* <Link to={`/bus/${route.slug}`} className="cursor" style={{textDecoration: "none"}}> */}
                        <Link to={`/bus/${route.from_city}-to-`+route.to_city} className="cursor" style={{textDecoration: "none"}}>
                          <img src="../images/Icon-metro-location.svg" className="popl-routs-icon1" />
                          <span>{route.from_city}</span>
                          <span className='to'> To </span>
                          <span>{route.to_city}</span>
                          <span> Bus</span>
                        </Link>
                      </li>
                    ))
                  }
                </ul>
              </div>
              {/* {
                routes.length>12 &&
                <div className="col-md-12">
                  <div className="text-center">
                    <Link routerlink="routes" className="border-btn bh" onClick={()=>setSeeMoreRoute(!seeMoreRoutes)}>
                      {
                        seeMoreRoutes==false ? 'See More' : 'See Less'
                      }
                    </Link>
                  </div>
                </div>
              } */}
            </div>
          </div>
        </section>
    </>
  )
}

export default TopRoutes
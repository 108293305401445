import React,{useState,useEffect} from 'react'
import axios from 'axios';
import Helper from '../../util/Helper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock, faCaretLeft, faCaretRight, faAngleDoubleRight, faAngleDoubleLeft } from '@fortawesome/free-solid-svg-icons';
import { faRupeeSign } from '@fortawesome/free-solid-svg-icons';
import { faChair } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import ViewSeat from './ViewSeat';
import useMediaQuery from '@mui/material/useMediaQuery';

const Bus = (props) => {
    const isMobile = useMediaQuery('(max-width: 767px)');
    const isDeskTop = useMediaQuery('(min-width: 768px)');
    const bookingInfoDtls = JSON.parse(localStorage.getItem('bookingDetails')) || {};
    const [showViewSeat,setShowViewSeat] = useState(
        bookingInfoDtls[props.bus.bus_id] ? true : false
    );
    const [showViaAllSec,setShowViaAllSec] = useState(false);
    const [showBordDrop, setShowBordDrop] = useState(false);
    const [showCancelPolicy, setShowCancelPolicy] = useState(false);

    const apiUrl = process.env.REACT_APP_API_URL;
    const apiKey = process.env.REACT_APP_API_KEY;
    const apiToken = process.env.REACT_APP_API_TOKEN;
    const [busInfo, setBusInfo] = useState([]);
    const [seatLayoutsLoading, setSeatLayoutsLoading] = useState({
        loadingMsg : 'Loading ......',
        showLoading : true
    });
    useEffect(() => {
        if(bookingInfoDtls[props.bus.bus_id]){
            setShowViewSeat(true);
            handleViewSeat(props.bus.bus_id);
        }
    }, [showViewSeat]);
    const handleViewSeat = async (val) => {

        try {
            axios.get(apiUrl + '/bus/bus-details/' + val+'?routeId='+props.bus.id+'&doj='+props.doj, {
                headers: {
                    'YESBUS_API_KEY': apiKey,
                    Authorization: `Bearer ${apiToken}`,
                }
            })
            .then((response) => {
                if (response.data.status === 200) {
                    setBusInfo(response.data.data);
                    setSeatLayoutsLoading({...seatLayoutsLoading,showLoading:false});
                    window.scrollTo(0, 120);
                    props.setSelectedBusId(props.bus.id);
                    props.setBlogShowStatus(false);
                }else{
                    setSeatLayoutsLoading({loadingMsg:response.data.message,showLoading:true});
                }
            })
            .catch((error) => {
                console.log(error);
                setSeatLayoutsLoading({loadingMsg:'Error fetching bus details',showLoading:true});
            });
        } catch (error) {
          console.log('Error fetching bus details:', error);
          setSeatLayoutsLoading({loadingMsg:'Error fetching bus details',showLoading:true});
        }
    };

    const dateOfJourney = props.doj;
    const dateParts = dateOfJourney.split('-');
    const journeyYear = parseInt(dateParts[2], 10);
    const journeyMonth = parseInt(dateParts[1], 10) - 1; // Months are zero-based (0-11)
    const journeyDay = parseInt(dateParts[0], 10);
    // console.log('hi',props.bus.bus_departure_time)
    // const departureTimeParts = props.bus.departure_time.split(':');
    const departureTimeParts = props.bus.bus_departure_time.split(':');
    const departureTime = new Date();
    departureTime.setFullYear(journeyYear);
    departureTime.setMonth(journeyMonth);
    departureTime.setDate(journeyDay);
    departureTime.setHours(parseInt(departureTimeParts[0], 10));
    departureTime.setMinutes(parseInt(departureTimeParts[1], 10));
    const currentTime = new Date();

    const timeDifferenceInHours = (departureTime - currentTime) / (1000 * 60 * 60);
    
    const bookingClosingTimeInHours = props.bus.booking_closing_time;
    
    return (
        <>
            {
                isDeskTop && 
                <div className=''>
                    <div className='bus_indi_sec'>
                        <div className="bus_name_type_cls">
                            <Link to={'/bus-ticket/'+props.source+'-to-'+props.destination+'/'+props.bus.bus_id}>
                                <h4 className="bus_name_cls">
                                    {props.bus.bus_name}
                                </h4>
                            </Link>
                            <span className="bus_type">{props.bus.bustypenames}</span>
                        </div>
                        <div className="bus_departure_cls">
                            <div className="bus_duration_time_cls">{Helper.formatTime(props.bus.departure_time)}</div>
                            <span dangerouslySetInnerHTML={{ __html: decodeURIComponent(props.source) }} />
                        </div>
                        <div className="bus_duration_cls">
                            <div className="icon_div_cls"><FontAwesomeIcon icon={faClock} /></div>
                            <span>{Helper.calculateHourDifference(props.bus.departure_time,props.bus.arrival_time)} hrs</span>
                        </div>
                        <div className="bus_departure_cls">
                            <div className="bus_duration_time_cls">{Helper.formatTime(props.bus.arrival_time)}</div>
                            <span dangerouslySetInnerHTML={{ __html: decodeURIComponent(props.destination) }} />
                        </div>
                        <div className="bus_duration_cls">
                            <div className="bus_fare_span_cls">
                            <FontAwesomeIcon icon={faRupeeSign} />
                            </div>
                            <span>{Number(props.bus.base_seater_fare_total)}/-</span>
                        </div>
                        <div className="bus_duration_cls">
                            <div className="bus_seat_span_icn">
                            <FontAwesomeIcon icon={faChair} />
                            </div>
                            <span className="bus_seat_span_cls">{props.bus.available_seats} Seats</span>
                        </div>
                        <div className='view_dtls_sec'>
                            {timeDifferenceInHours > bookingClosingTimeInHours ? (
                                <Link
                                className='view_seat'
                                onClick={(e) => {
                                    setShowViewSeat(localStorage.getItem('bookingDetails') ? false : true);
                                    handleViewSeat(props.bus.bus_id);
                                }}
                                >
                                View Seats
                                </Link>
                            ) : (
                                <Link className='booking_closed'>Booking Closed</Link>
                            )}
                            
                        </div>
                    </div>
                    <div className='bus_dtls_div' onMouseEnter={()=>setShowViaAllSec(true)} onMouseLeave={()=>setShowViaAllSec(false)}>
                        <ul>
                            <li className="via_cls">VIA : {props.bus.via}</li>
                            <li className="boarding_point_cls" style={showViaAllSec===true ? {display:''} : {display:'none'}}><Link onClick={()=>{setShowCancelPolicy(false);setShowBordDrop(!showBordDrop)}}><span> Boarding &amp; Dropping Points</span></Link></li>
                            <li className="cancellation_policy_cls" style={showViaAllSec===true ? {display:''} : {display:'none'}} onClick={()=>{setShowBordDrop(false);setShowCancelPolicy(!showCancelPolicy)}}><Link><span> Cancellation Policy</span></Link></li>
                        </ul>
                    </div>
                    <div className='bus_dtls_div_sec'>
                        <div className='bus_borddrop_cancellation_sec' style={showBordDrop===true ? {display:''} : {display:'none'}}>
                            <div className='left_side_details'>
                                <div className='bd_side_head'>Boarding Point</div>
                                <ul>
                                    {
                                        props.bus.boardingpoints.map((item,index)=>{
                                            return(
                                                <li key={index}>{item.stoppage_point}</li>
                                            );
                                        })
                                    }
                                </ul>
                            </div>
                            <div className='left_side_details'>
                                <div className='bd_side_head'>Dropping Point</div>
                                <ul>
                                    {
                                        props.bus.droppingpoints.map((item,index)=>{
                                            return(
                                                <li key={index}>{item.stoppage_point}</li>
                                            );
                                        })
                                    }
                                </ul>
                            </div>
                        </div>
                        <div className='bus_borddrop_cancellation_sec' style={showCancelPolicy===true ? {display:''} : {display:'none'}}>
                            <div className='left_side_details'>
                                <div className='bd_side_head'>Cut off time</div>
                                <ul>
                                    {
                                        props.bus.cancellation_before_days.map((item,index)=>{
                                            return(
                                                <li key={index}>Before {item} Hours</li>
                                            );
                                        })
                                    }
                                </ul>
                            </div>
                            <div className='left_side_details'>
                                <div className='bd_side_head'>Refund Percentage</div>
                                <ul>
                                    {
                                        props.bus.cancellation_charge.map((item,index)=>{
                                            return(
                                                <li key={index}>{100-item}%</li>
                                            );
                                        })
                                    }
                                </ul>
                            </div>
                        </div>
                    </div>
                    <ViewSeat 
                        showViewSeat={showViewSeat} 
                        setShowViewSeat={setShowViewSeat} 
                        boardingpoints={props.bus.boardingpoints} 
                        droppingpoints={props.bus.droppingpoints} 
                        busInfo={busInfo} routeId={props.bus.id} 
                        source={props.source} 
                        departure_time={Helper.formatTime(props.bus.departure_time)} 
                        destination={props.destination} 
                        arrival_time={Helper.formatTime(props.bus.arrival_time)} 
                        bus_name={props.bus.bus_name} 
                        duration={Helper.calculateHourDifference(props.bus.departure_time,props.bus.arrival_time)} 
                        bookedSeats={props.bus.booked_seats ? props.bus.booked_seats : []} 
                        doj={props.doj} 
                        bus_types={props.bus.bustypenames} 
                        seatLayoutsLoading={seatLayoutsLoading} 
                        setSelectedBusId={props.setSelectedBusId} 
                        setBlogShowStatus={props.setBlogShowStatus} 
                    />
                </div>
            }
            {
                isMobile &&
                <div className='mob_mode'>
                    <div className='bus_indi_sec'>
                        <div className='bus_indi_sec_first_div'>
                            <div className="bus_name_type_cls">
                                <Link to={'/bus-ticket/'+props.source+'-to-'+props.destination+'/'+props.bus.bus_id}>
                                    <h4 className="bus_name_cls"> 
                                    {props.bus.bus_name}
                                    </h4>
                                </Link>
                                <span className="bus_type">{props.bus.bustypenames}</span>
                            </div>
                            <div className="bus_duration_cls">
                                <span className="bus_seat_span_cls">{props.bus.available_seats} Seats</span>
                            </div>
                        </div>
                        <div className='bus_indi_sec_first_div'>
                            <div className='bus_src_details'>
                                <div className="bus_departure_cls">
                                    <div className="bus_duration_time_cls">{Helper.formatTime(props.bus.departure_time)}</div>
                                </div>
                                <div className="bus_duration_cls">
                                    <FontAwesomeIcon icon={faCaretLeft} />
                                    <span>-- {Helper.calculateHourDifference(props.bus.departure_time,props.bus.arrival_time)} hrs --</span>
                                    <FontAwesomeIcon icon={faCaretRight} />
                                </div>
                                <div className="bus_departure_cls">
                                    <div className="bus_duration_time_cls">{Helper.formatTime(props.bus.arrival_time)}</div>
                                </div>
                            </div>
                            <div className="bus_duration_cls bus_fare_mob">
                                <span>₹{Number(props.bus.base_seater_fare_total)}/-</span>
                            </div>
                        </div>
                        <div className="bus_indi_sec_last_div">
                            <div className='via_sec'>
                                <div className='bus_dtls_div'>
                                    <ul>
                                        <li className="via_cls">VIA : <span>{props.bus.via}</span></li>
                                        {
                                            showViaAllSec===true ?
                                            <li className="via_cls" onClick={()=>setShowViaAllSec(false)}><FontAwesomeIcon icon={faAngleDoubleLeft} /></li> 
                                            :
                                            <li className="via_cls" onClick={()=>setShowViaAllSec(true)}><FontAwesomeIcon icon={faAngleDoubleRight}/></li> 
                                        }
                                    </ul>
                                </div>
                            </div>
                            <div className='view_dtls_sec'>
                                {timeDifferenceInHours > bookingClosingTimeInHours ? (
                                    <Link
                                    className='view_seat'
                                    onClick={(e) => {
                                        setShowViewSeat(localStorage.getItem('bookingDetails') ? false : true);
                                        handleViewSeat(props.bus.bus_id);
                                    }}
                                    >
                                    View Seats
                                    </Link>
                                ) : (
                                    <Link className='booking_closed'>Booking Closed</Link>
                                )}
                            </div>
                        </div>
                        <div className='bus_indi_sec_last_div_details' style={showViaAllSec===true ? {display:''} : {display:'none'}}>
                            <div className='boarding_point_cls bus_detls_hd'>
                                <Link onClick={()=>{setShowCancelPolicy(false);setShowBordDrop(!showBordDrop);setShowViaAllSec(true)}}><span> Boarding &amp; Dropping Points</span></Link>
                            </div>
                            <div className='cancellation_policy_cls bus_detls_hd'>
                                <Link onClick={()=>{setShowBordDrop(false);setShowCancelPolicy(!showCancelPolicy);setShowViaAllSec(true)}}><span> Cancellation Policy</span></Link>
                            </div>
                        </div>
                        <div className='bus_dtls_div_sec' style={showViaAllSec===true ? {display:''} : {display:'none'}}>
                            <div className='bus_borddrop_cancellation_sec' style={showBordDrop===true ? {display:''} : {display:'none'}}>
                                <div className='left_side_details'>
                                    <div className='bd_side_head'>Boarding Point</div>
                                    <ul>
                                        {
                                            props.bus.boardingpoints.map((item,index)=>{
                                                return(
                                                    <li key={index}>{item.stoppage_point}</li>
                                                );
                                            })
                                        }
                                    </ul>
                                </div>
                                <div className='left_side_details'>
                                    <div className='bd_side_head'>Dropping Point</div>
                                    <ul>
                                        {
                                            props.bus.droppingpoints.map((item,index)=>{
                                                return(
                                                    <li key={index}>{item.stoppage_point}</li>
                                                );
                                            })
                                        }
                                    </ul>
                                </div>
                            </div>
                            <div className='bus_borddrop_cancellation_sec' style={showCancelPolicy===true ? {display:''} : {display:'none'}}>
                                <div className='left_side_details'>
                                    <div className='bd_side_head'>Cut off time</div>
                                    <ul>
                                        {
                                            props.bus.cancellation_before_days.map((item,index)=>{
                                                return(
                                                    <li key={index}>Before {item} Hours</li>
                                                );
                                            })
                                        }
                                    </ul>
                                </div>
                                <div className='left_side_details'>
                                    <div className='bd_side_head'>Refund Percentage</div>
                                    <ul>
                                        {
                                            props.bus.cancellation_charge.map((item,index)=>{
                                                return(
                                                    <li key={index}>{100-item}%</li>
                                                );
                                            })
                                        }
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <ViewSeat 
                        showViewSeat={showViewSeat} 
                        setShowViewSeat={setShowViewSeat} 
                        boardingpoints={props.bus.boardingpoints} 
                        droppingpoints={props.bus.droppingpoints} 
                        busInfo={busInfo} routeId={props.bus.id} 
                        source={props.source} 
                        departure_time={Helper.formatTime(props.bus.departure_time)} 
                        destination={props.destination} 
                        arrival_time={Helper.formatTime(props.bus.arrival_time)} 
                        bus_name={props.bus.bus_name} 
                        duration={Helper.calculateHourDifference(props.bus.departure_time,props.bus.arrival_time)} 
                        bookedSeats={props.bus.booked_seats ? props.bus.booked_seats : []} 
                        doj={props.doj} 
                        seatLayoutsLoading={seatLayoutsLoading} 
                        setSelectedBusId={props.setSelectedBusId} 
                        setBlogShowStatus={props.setBlogShowStatus} 
                    />
                </div>
            }
        </>
    )
}

export default Bus
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';


import Home from './components/Home';
import Header from './components/layout/Header';
import Footer from './components/layout/Footer';
import BusList from './components/BusList';
import BusById from './components/BusById';
import Preview from './components/journey/Preview';
import Ticket from './components/journey/Ticket';
import PrivacyPolicy from './components/PrivacyPolicy';
import TermAndCondition from './components/TermAndCondition';
import CancellationAndRefundPolicy from './components/CancellationAndRefundPolicy';
import AboutUs from './components/AboutUs';
import ContactUs from './components/ContactUs';

import LocationWrapper from "./LocationWrapper";
import Notification from './components/home/Notification';

import SeoContextProvider from './context/SeoContext';

import useMediaQuery from '@mui/material/useMediaQuery';
import CancelTicket from './components/journey/CancelTicket';
import FindTicket from './components/journey/FindTicket';
import SiteMap from './components/SiteMap';
import NotFoundPage from './components/NotFoundPage';

function App() {
  const isMobile = useMediaQuery('(max-width: 767px)');
  const hideFooterOnPages = ['/preview/*','/sitemap'];
  const hideHeaderAndFooterOnPages = ['/sitemap'];
  return (
    
    <Router>
      {/* <Notification/> */}
      {!hideHeaderAndFooterOnPages.includes(window.location.pathname) && <Notification />}
      {/* <Header /> */}
      {!hideHeaderAndFooterOnPages.includes(window.location.pathname) && <Header />}
      <Routes>
        
        <Route path="/" element={
          <SeoContextProvider endpoint="home">
            <Home />
          </SeoContextProvider>
        } />
        <Route path="/privacy-policy/" element={
          <SeoContextProvider endpoint="privacy-policy">
            <PrivacyPolicy />
          </SeoContextProvider>
        } />
        <Route path="/about-us/" element={
          <SeoContextProvider endpoint="about-us">
            <AboutUs />
          </SeoContextProvider>
        } />
        <Route path="/contact-us/" element={
          <SeoContextProvider endpoint="contact-us">
            <ContactUs />
          </SeoContextProvider>
        } />
        <Route path="/terms-conditions/" element={
          <SeoContextProvider endpoint="terms-conditions">
            <TermAndCondition />
          </SeoContextProvider>
        } />
        <Route path="/cancellation-refund-policy/" element={
          <SeoContextProvider endpoint="cancellation-refund-policy">
            <CancellationAndRefundPolicy />
          </SeoContextProvider>
        } />
        <Route path="/bus/:sourcedsest/:optionaldoj?" element={
          <SeoContextProvider endpoint="bus">
            <LocationWrapper>
              <BusList />
            </LocationWrapper>
          </SeoContextProvider>
        } />
        <Route path="/bus-ticket/:sourcedsest/:optionalbusid?/:optionaldoj?" element={
          <SeoContextProvider endpoint="bus">
            <LocationWrapper>
              <BusById />
            </LocationWrapper>
          </SeoContextProvider>
        } />
        <Route path="/preview/*" element={
          <SeoContextProvider endpoint="preview">
            <Preview />
          </SeoContextProvider>
        } />
        <Route path="/ticket/*" element={
          <Ticket />
        } />
        <Route path="/cancel-ticket/" element={
          <CancelTicket />
        } />
        <Route path="/find-ticket/" element={
          <FindTicket />
        } />
        <Route
          path="/sitemap/"
          element={
              <SiteMap />
          }
        />
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
      {/* {
        !hideFooterOnPages.includes(window.location.pathname) && 
        (!isMobile ? <Footer /> : '')
      } */}
      {
        !isMobile ?
          (
            !hideFooterOnPages.includes(window.location.pathname) && 
            <Footer />
          )
        : (
          !hideFooterOnPages.includes(window.location.pathname) && 
          <Footer />
        )
      }
    </Router>
  );
}

export default App;

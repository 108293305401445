import React from 'react'

const PrivacyPolicy = () => {
  return (
    <>
        <section class="terms_and_cond">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-lg-12 col-md-12">
                        <div class="terms_and_cond_main privay_policy">
                            <h2>Privacy Policy</h2>
                            <p>yesbus.in recognizes the importance of privacy of its website users and also of maintaining confidentiality of the information provided by its users as a responsible data controller and data processor.</p>
                            <p>This Privacy Policy provides for the practices for handling and securing user’s Personal Information (defined hereunder) by yesbus.in and its subsidiaries and affiliates.</p>
                            <p>This Privacy Policy is applicable and eligible to any person (“User”) who purchases, intends to purchase, or inquire about any product or service made available by yesbus.in through any of  yesbus.in customer interface channels including its website, mobile application(iOS & Android), mobile site, & offline channels including call centers and offices (collectively referred herein as “Sales Channels”).</p>
                            <p>We at yesbus.in, value the relationship we have with our valuable customers and our web site visitors, and are committed to responsible information and handling practices. We take the privacy of our customers very seriously and want you to feel comfortable whenever you visit our web site.</p>
                            <p>By using or accessing yesbus.in Site, certain information about you or website user, such as Internet protocol (IP)addresses, navigation through the Site and the time spent, the systems and programs used, along with other similar information, may be stored on our servers or 3rd party servers like Google Analytics. Such stored information may not specifically identify you. Please be informed that the same may be used by us for web site traffic analysis or for such other purposes as we may deem appropriate.</p>
                            <p>If you provide unique identifying information, such as name, address and other information to us, such information may be used for statistical and/or other purposes. Any personally identifiable information you submit while booking with yesbus.in, will ONLY be disclosed to travel suppliers/operators who are linked to your bookings. We will not disclose, sell, share or in any way reveal your information to any other third party.</p>
                            <p>yesbus.in may use your personal contact information like Email Address, Mobile number to contact, alert information about your bus tour or travel or any offers from yesbus.in or partners.</p>
                            <p>We want you to feel confident about using yesbus.in to plan and purchase your bus tickets, so we are committed to protect your information we collect. We have implemented appropriate administrative, technical, and physical security procedures to help protect the personal information you provide to us. To prevent unauthorized access, maintain data accuracy, and ensure correct use of information, we will employ reasonable and current Internet security methods and technologies</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </>
  )
}

export default PrivacyPolicy
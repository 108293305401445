import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
const Footer = () => {
  return (
    <FooterSection className="new_footer_design_sec">
      <section className="footer">
        <div className="footer-top">
          <div className="container-fluid">
            <div className="row desk_mode">
              <div className="col-md-4">
                <div className="footer-logo desk_mode">
                  <img alt="logo-YESBUS" title="" src="/images/yesbuslogo.png" />
                </div>
                <p className="footer-logo-about desk_mode">YESBUS is the Odisha's first and largest online bus ticket booking service provider trusted by over thousands happy customers and hundred of bus operator. Anyone can book bus ticket booking through our website and mobile apps for all major routes in Odisha and other surrounded states.</p>
              </div>
              <div className="col-md-8 desk_mode">
                <div className="row">
                  <div className="col-md-8">
                    <div className="row">
                      <div className="col-md-4">
                        <div className="foot-link ">
                          <h4 className='desk_mode'>About YESBUS</h4>
                          <ul>
                            <li>
                              <Link to="/about-us">About Us</Link>
                            </li>
                            <li>
                              <Link to="/contact-us">Contact Us</Link>
                            </li>
                            <li>
                              <Link to="/routes">Popular Routes</Link>
                            </li>
                            <li>
                              <Link to="/faq">FAQ</Link>
                            </li>
                            <li>
                              <Link to="/privacy-policy">Privacy Policy</Link>
                            </li>
                            <li>
                              <Link to="/cancellation-refund-policy">Cancellation & Refund Policy</Link>
                            </li>
                            <li>
                              <Link to="/terms-conditions">Terms &amp; Conditions</Link>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="foot-link">
                          <h4>Information</h4>
                          <ul>
                            <li>
                              <Link to="/agent-login">Agent Login</Link>
                            </li>
                            <li>
                              <Link to="/franchise-login">Franchise Login</Link>
                            </li>
                            <li>
                              <Link to="/affiliates-login">Affiliates Login</Link>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="foot-link">
                          <h4>Our Partners</h4>
                          <ul>
                            <li>
                              <Link to="">BOOK CAB/TAXI </Link>
                            </li>
                            <li>
                              <Link to="">YESBUS TRAVELS</Link>
                            </li>
                            {/* <li>
                              <Link to="">SEOFIED</Link>
                            </li> */}
                            <li>
                              <Link to="/api-reference">API Reference</Link>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="foot-cnt">
                      <h4>Contact Us</h4>
                      <ul>
                        <li>Email : contact@yesbus.in</li>
                        <li>Phone : +91-8434507707</li>
                      </ul>
                    </div>
                    <div className="payment-option mt-2">
                      <p>Payment Option</p>
                      <img src="/images/payment-option.png" alt="payment-methods" title="" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='row mob_mode mob_footer_sec'>
              <div className='footer_about_us_sec'>
                <h4 className='mob_mode footer_head_sec'>About Us</h4>
                <p className='footer-logo-about mob_mode'>YESBUS is the Odisha's first and largest online bus ticket booking service provider trusted by over thousands happy customers and hundred of bus operator. <br/><br/>Anyone can book bus ticket booking through our website and mobile apps for all major routes in Odisha and other surrounded states.</p>
              </div>
              <div className='footer_details_sec mob_mode'>
                <div className='important_links_sec'>
                  <h4>Important Links</h4>
                  <div className='important_links_details'>
                    <ul className='important_links'>
                      <li>
                        <Link to="/cancel-ticket">Cancel Ticket</Link>
                      </li>
                      <li>
                        <Link to="/find-ticket">Find Ticket</Link>
                      </li>
                      <li>
                        <Link to="/about-us">About Us</Link>
                      </li>
                      <li>
                        <Link to="/contact-us">Contact Us</Link>
                      </li>
                      <li>
                        <Link to="/routes">Popular Routes</Link>
                      </li>
                      <li>
                        <Link to="/faq">FAQ</Link>
                      </li>
                      <li>
                        <Link to="/privacy-policy">Privacy Policy</Link>
                      </li>
                      <li>
                        <Link to="/cancellation-refund-policy">Cancellation & Refund Policy</Link>
                      </li>
                      <li>
                        <Link to="/terms-conditions">Terms &amp; Conditions</Link>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className='info_our_partner_sec'>
                  <div className='information_div'>
                    <h4>Information</h4>
                    <div className='under_details'>
                      <ul>
                        <li>
                          <Link to="/agent-login">Agent Login</Link>
                        </li>
                        <li>
                          <Link to="/franchise-login">Franchise Login</Link>
                        </li>
                        <li>
                          <Link to="/affiliates-login">Affiliates Login</Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className='our_partner_div'>
                    <h4>Our Partners</h4>
                    <div className='under_details ourpartner_details'>
                      <ul>
                        <li>
                          <Link to="">BOOK CAB/TAXI </Link>
                        </li>
                        <li>
                          <Link to="">YESBUS TRAVELS</Link>
                        </li>
                        {/* <li>
                          <Link to="">SEOFIED</Link>
                        </li> */}
                        <li>
                          <Link to="/api-reference">API Reference</Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className='contact_us_sec'>
                  <h4>Quick Contact</h4>
                  <div className='contact_us_details'>
                    <div><label htmlFor="">Email :</label> contact@yesbus.in</div>
                    <div><label htmlFor="">Phone :</label> +91-8434507707</div>
                    <div>
                      <h6>Payment Option</h6>
                      <img src="/images/payment-option.png" alt="payment-methods" title="" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-bottom">
          <div className="container-fluid">
            <div className="row align-items-center">
              <div className="col-md-6">
                <div className="footer-bottom-left">
                  <ul>
                    <li>
                      <Link to="https://www.facebook.com/YesBus">
                        <img src="/images/icon-facebook.svg" />
                      </Link>
                    </li>
                    <li>
                      <Link to="https://twitter.com/YesbusI">
                        <img src="/images/twitter.webp" />
                      </Link>
                    </li>
                    <li>
                      <Link to="https://www.instagram.com/yesbus_india/">
                        <img src="/images/instagram.webp" />
                      </Link>
                    </li>
                    {/* <li>
                      <Link href="">
                        <img src="/images/icon-linkedin.svg" />
                      </Link>
                    </li>
                    <li>
                      <Link href="">
                        <img src="/images/icon-pinintrest.svg" />
                      </Link>
                    </li> */}
                    <li>
                      <Link to="https://www.youtube.com/channel/UCcnx3ixXMtGwKZL-3qxLHLg">
                        <img src="/images/youtube.webp" />
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-md-6 text-right">
                <p className="m-0">Copyright @ YESBUS . All Rights Reserved</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </FooterSection>
  )
}
const FooterSection = styled.footer`
  @media (min-width:250px) and (max-width:452px) {
    p.footer-logo-about{
      font-size: 13px !important;
    }
    .important_links_sec h4{
      font-size: 18px !important;
    }
    .important_links_details ul li a{
      font-size: 13px !important;
      padding: 1px 4px !important;
    }
    .info_our_partner_sec h4{
      font-size: 16px !important;
    }
    .info_our_partner_sec ul li{
      font-size: 13px !important;
    }
    .footer_details_sec{
      display: flex !important;
      flex-direction: column !important;
    }
    .info_our_partner_sec{
      justify-content: space-between !important;
      align-items: baseline !important;
    }
    .important_links_sec{
      flex: none !important;
    }
  }
  @media (min-width: 768px) and (max-width: 991px) {
    .info_our_partner_sec{
      flex: 0 0 50%;
    }
  }
  @media (max-width:990px) {
    .important_links_sec{
      flex: 0 0 100%;
    }
    .mob_footer_sec{
      display: flex;
      flex-wrap: wrap;
      gap: 15px;
    }
    .footer_details_sec{
      display: flex;
      flex-wrap: wrap;
      gap: 15px;
      justify-content: space-between;
    }
    .footer-bottom{
      .align-items-center{
        gap: 10px;
        text-align: center;
        .text-right{
          text-align: left;
          p{
            font-size:10px;
          }
        }
      }
    }
    .footer_head_sec{
      color: transparent;
      right: -15px;
      left: auto;
      top: -15px;
      -webkit-text-stroke: 1.9px rgb(46, 49, 136);
      text-decoration: underline;
      text-decoration-thickness: 3px;
      text-underline-offset: 5px
    }
    p.footer-logo-about{
      font-size: 15px;
      margin-bottom: 0px;
    }
    .contact_us_sec{
      h4{
        color: rgb(46, 49, 136);
        margin-bottom: 16px;
        font-size: 20px;
        text-decoration: underline;
        text-decoration-thickness: 2px;
        text-underline-offset: 5px;
        text-decoration-color: rgb(200, 71, 59);
      }
      .contact_us_details{
        display: flex;
        flex-direction: column;
        gap: 5px;
        color: rgb(98, 95, 95);
        div{
          label{
            font-weight: 600;
            margin-right: 5px;
          }
          h6{
            margin-top: 15px;
            color: rgb(200, 71, 59);
          }
        }
      }
    }
    .info_our_partner_sec{
      display: flex;
      justify-content: space-between;
      align-items: baseline;
      gap:50px;
      h4{
        color: rgb(46, 49, 136);
        margin-bottom: 16px;
        font-size: 18px;
        text-decoration: underline;
        text-decoration-thickness: 2px;
        text-underline-offset: 5px;
        text-decoration-color: rgb(200, 71, 59);
      }
      ul{
        padding: 0px;
        li{
          font-size: 14px;
          margin-bottom: 5px;
          a{
            color: #625f5f;
            :hover{
              color: rgb(200, 71, 59);
            }
          }
        }
      }
      .our_partner_div{
        .under_details.ourpartner_details{
          text-align: right;
        }
      }
      
    }
    .important_links_details{
      ul{
        padding: 0px;
        li{
          font-size: 13px;
          margin-bottom: 5px;
          display: inline-block;
          margin-right: 8px;
          a{
            border: 1px solid #adb1c2;
            border-radius: 6px;
            color: #888;
            display: inline-block;
            font-size: 15px;
            margin-bottom: 4px;
            padding: 1px 6px;
            :hover{
              color: rgb(200, 71, 59);
            }
          }
        }
      }
    }
    
    .important_links_sec{
      h4{
        color: rgb(46, 49, 136);
        margin-bottom: 16px;
        font-size: 20px;
        text-decoration: underline;
        text-decoration-thickness: 2px;
        text-underline-offset: 5px;
        text-decoration-color: rgb(200, 71, 59);
      }
    }
  }
`;
export default Footer